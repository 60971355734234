<template>
  <div class="newscenter">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">News Center</div>
      <h1 class="border_text_title">新闻中心</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">新闻中心</span>
    </div>
    <div class="group_news_container">
      <transition name="myfade" mode="out-in">
        <div class="border_text_box" v-if="num === 0" key="0">
          <div class="border_text">Group News</div>
          <h3 class="border_text_title">集团新闻</h3>
        </div>
        <div class="border_text_box" v-if="num === 1" key="1">
          <div class="border_text">Announcement</div>
          <h3 class="border_text_title">通知公告</h3>
        </div>
        <div class="border_text_box" v-if="num === 2" key="2">
          <div class="border_text">Industry News</div>
          <h3 class="border_text_title">行业资讯</h3>
        </div>
        <div class="border_text_box" v-if="num === 3" key="3">
          <div class="border_text">Media News</div>
          <h3 class="border_text_title">媒体新闻</h3>
        </div>
        <div class="border_text_box" v-if="num === 4" key="4">
          <div class="border_text">Special Column</div>
          <h3 class="border_text_title">专题专栏</h3>
        </div>
      </transition>
      <div class="group_news_box container_width">
            <div class="sidebar_box">
              <ul>
                <li :class="{current_li : num === 0}" @click="tab(0)">集团新闻</li>
                <li :class="{current_li : num === 1}" @click="tab(1)">通知公告</li>
                <li :class="{current_li : num === 2}" @click="tab(2)">行业资讯</li>
                <li :class="{current_li : num === 3}" @click="tab(3)">媒体新闻</li>
                <li :class="{current_li : num === 4}" @click="tab(4)">专题专栏</li>
              </ul>
            </div>
            <div class="new_content_box">
              <div class="new_content" v-if="list_num === 0">
                <ul>
                  <li :class="{right_image_in_animate : num === 0, right_image_out_animate : num !== 0}">
                    <div class="right_image">
                      <img src="../assets/imgs/newscenter/new_imag_1.webp" alt="">
                    </div>
                    <div class="content" :class="{text_mask_layer_in_animate : num === 0, text_mask_layer_out_animate : num !== 0}">
                      <h2 :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">关爱特殊人群 贤牛志愿者陪伴残障人员走进海洋公园</h2>
                      <div class="time" :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 0, text_out_animate : num !== 0}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                  </li>
                  <li :class="{left_image_in_animate : num === 0, left_image_out_animate : num !== 0}">
                    <div class="content content_left" :class="{text_mask_layer_in_animate : num === 0, text_mask_layer_out_animate : num !== 0}">
                      <h2 :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">春暖花开丨一起向未来</h2>
                      <div class="time" :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 0, text_out_animate : num !== 0}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                    <div class="left_image">
                      <img src="../assets/imgs/newscenter/new_imag_2.webp" alt="">
                    </div>
                  </li>
                  <li :class="{right_image_in_animate : num === 0, right_image_out_animate : num !== 0}">
                    <div class="right_image">
                      <img src="../assets/imgs/newscenter/new_imag_3.webp" alt="">
                    </div>
                    <div class="content" :class="{text_mask_layer_in_animate : num === 0, text_mask_layer_out_animate : num !== 0}">
                      <h2 :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">关爱特殊人群 贤牛志愿者陪伴残障人员走进海洋公园</h2>
                      <div class="time" :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 0, text_out_animate : num !== 0}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                  </li>
                  <li :class="{left_image_in_animate : num === 0, left_image_out_animate : num !== 0}">
                    <div class="content content_left" :class="{text_mask_layer_in_animate : num === 0, text_mask_layer_out_animate : num !== 0}">
                      <h2 :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">春暖花开丨一起向未来</h2>
                      <div class="time" :class="{title_time_in_animate : num === 0, title_time_out_animate : num !== 0}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 0, text_out_animate : num !== 0}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                    <div class="left_image">
                      <img src="../assets/imgs/newscenter/new_imag_4.webp" alt="">
                    </div>
                  </li>
                </ul>
              </div>
              <div class="new_content" v-if="list_num === 1">
                <ul>
                  <li :class="{right_image_in_animate : num === 1, right_image_out_animate : num !== 1}">
                    <div class="right_image">
                      <img src="../assets/imgs/newscenter/new_imag_4.webp" alt="">
                    </div>
                    <div class="content" :class="{text_mask_layer_in_animate : num === 1, text_mask_layer_out_animate : num !== 1}">
                      <h2 :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">关爱特殊人群 员走进海洋公园</h2>
                      <div class="time" :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">2022-99-14</div>
                      <div class="text" :class="{text_in_animate : num === 1, text_out_animate : num !== 1}">、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                  </li>
                  <li :class="{left_image_in_animate : num === 1, left_image_out_animate : num !== 1}">
                    <div class="content content_left" :class="{text_mask_layer_in_animate : num === 1, text_mask_layer_out_animate : num !== 1}">
                      <h2 :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">春暖花开丨一起向未来</h2>
                      <div class="time" :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 1, text_out_animate : num !== 1}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                    <div class="left_image">
                      <img src="../assets/imgs/newscenter/new_imag_3.webp" alt="">
                    </div>
                  </li>
                  <li :class="{right_image_in_animate : num === 1, right_image_out_animate : num !== 1}">
                    <div class="right_image">
                      <img src="../assets/imgs/newscenter/new_imag_2.webp" alt="">
                    </div>
                    <div class="content" :class="{text_mask_layer_in_animate : num === 1, text_mask_layer_out_animate : num !== 1}">
                      <h2 :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">关爱特殊人群 贤牛志愿者陪伴残障人员走进海洋公园</h2>
                      <div class="time" :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 1, text_out_animate : num !== 1}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                  </li>
                  <li :class="{left_image_in_animate : num === 1, left_image_out_animate : num !== 1}">
                    <div class="content content_left" :class="{text_mask_layer_in_animate : num === 1, text_mask_layer_out_animate : num !== 1}">
                      <h2 :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">春暖花开丨一起向未来</h2>
                      <div class="time" :class="{title_time_in_animate : num === 1, title_time_out_animate : num !== 1}">2022-04-14</div>
                      <div class="text" :class="{text_in_animate : num === 1, text_out_animate : num !== 1}">5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</div>
                      <a href="/news-details" class="go_button">详情 <img src="../assets/imgs/newscenter/go.webp" alt="" class="go_new"></a>
                    </div>
                    <div class="left_image">
                      <img src="../assets/imgs/newscenter/new_imag_1.webp" alt="">
                    </div>
                  </li>
                </ul>
              </div>
            </div>
      </div>
    </div>
    <div class="paginator_box container_width">
      <ul class="paginator">
        <img src="../assets/imgs/newscenter/previous.webp" alt="" class="previous">
        <li class="current_page">1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <img src="../assets/imgs/newscenter/next.webp" alt="" class="next">
      </ul>
    </div>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss' 
export default {
  name: "newscenter",
  data() {
    return {
      num:0,
      list_num: 0
    }
  },
  methods: {
    tab(num) {
      this.num = num
      console.log(this.num);
      setTimeout(() => {
        this.list_num = num
      }, 1300)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.newscenter {
    .contactus_banner {
        background: url('../assets/imgs/newscenter/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
    .group_news_container {
      .group_news_box {
        @include flextoprl();
        .sidebar_box {
          width: 11%;
          border-right: 0.5px solid #c5c5c5;
          position: sticky;
          top: 140px;
          ul {
            li {
              cursor: pointer;
              font-size: 16px;
              color: #464949;
              position: relative;
            }
            li::before {
              content: '';
              position: absolute;
              width: 12px;
              height: 12px;
              background: #c5c5c5;
              bottom: 50%;
              left: -25px;
              transform: translateY(50%);
              border-radius: 12px;
            }
            li:not(:last-child) {
              margin-bottom: 45px;
            }
            .current_li {
              font-weight: bold;
            }
            .current_li::before {
              background: linear-gradient(135deg,#73e0a9, #5b68df 100%);
            }
          }
        }
        .new_content_box {
          width: 82%;
          .new_content {
            ul {
              li {
                @include flexrl();
                margin-bottom: 100px;
                animation-fill-mode: both;
                .content {
                  color: #464949;
                  line-height: 20px;
                  font-size: 13px;
                  flex: 2;
                  h2 {
                    font-size: 24px;
                    font-weight: bold;
                    color: #161616;
                    line-height: 36px;
                  }
                  .time {
                    font-weight: bold;
                    margin-bottom: 20px;
                  }
                  .text{
                    overflow: hidden;
                    position: relative;
                  }
                  .text::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: rgb(255, 255, 255);
                    position: absolute;
                    top: -100%;
                  }
                  .go_button {
                    @include flexrowright();
                    margin-top: 30px;
                    color: #000;
                    font-weight: bold;
                    .go_new {
                      width: 26px;
                      margin: 0 0 0 10px;
                    }
                  }
                }
                .content_left {
                  h2, .time {
                    text-align: right;
                  }
                  .go_button {
                    @include flexrowleft();
                  }
                }
                .left_image {
                  width: 100%;
                  margin-left: 30px;
                  position: relative;
                  overflow: hidden;
                  flex: 1;
                  img {
                    width: 100%;
                    margin-left: 0px;
                  }
                }
                .right_image {
                  width: 100%;
                  margin-right: 30px;
                  position: relative;
                  overflow: hidden;
                  flex: 1;
                  img {
                    width: 100%;
                  }
                }
                .right_image::before {
                  content: '';
                  width: 100%;
                  height: 50%;
                  background: rgb(255, 255, 255);
                  position: absolute;
                }
                .right_image::after {
                  content: '';
                  width: 100%;
                  height: 50%;
                  background: rgb(255, 255, 255);
                  position: absolute;
                  top: 50%;
                }
                .left_image::before {
                  content: '';
                  width: 100%;
                  height: 50%;
                  background: rgb(255, 255, 255);
                  position: absolute;
                }
                .left_image::after {
                  content: '';
                  width: 100%;
                  height: 50%;
                  background: rgb(255, 255, 255);
                  position: absolute;
                  top: 50%;
                }
              }
              .right_image_in_animate {
                .right_image::before {
                  animation: coverIn1 1.3s;
                  animation-fill-mode: both;
                }
                .right_image::after {
                  animation: coverIn1 1.3s .15s;
                  animation-fill-mode: both;
                }
              }
              .right_image_out_animate {
                .right_image::before {
                  animation: coverOut1 1.3s;
                }
                .right_image::after {
                  animation: coverOut1 1.3s .15s;
                }
              }
              .left_image_in_animate {
                .left_image::before {
                  animation: evenIn1 1.3s;
                  animation-fill-mode: both;
                }
                .left_image::after {
                  animation: evenIn1 1.3s .15s;
                  animation-fill-mode: both;
                }
              }
              .left_image_out_animate {
                .left_image::before {
                  animation: evenOut1 1.3s;
                }
                .left_image::after {
                  animation: evenOut1 1.3s .15s;
                }
              }
              .title_time_in_animate {
                animation: fadeIn 1.3s;
                animation-fill-mode: both;
              }
              .title_time_out_animate {
                animation: fadeOut 1.3s;
              }
              .text_in_animate {
                animation: coverIn_p 1.3s;
                animation-fill-mode: both;
              }
              .text_out_animate {
                animation: coverOut_p 1.3s;
              }
              .text_mask_layer_in_animate {
                .text::before {
                  animation: coverIn_pb 1.3s;
                  animation-fill-mode: both;
                }
              }
              .text_mask_layer_out_animate {
                .text::before {
                  animation: coverOut_pb 1.3s;
                }
              }
            }
          }
        }
      }
      /* 效果过程 */
      .myfade-enter-active,
      .myfade-leave-active {
        transition: all 0.5s linear;
      }
      /* 进场的瞬间与离场的效果添加 */
      .myfade-enter,
      .myfade-leave-to {
        opacity: 0;
        transform: translateY(20px);
      }
    }
}
@media screen and (max-width: 1160px){}
@media screen and (max-width: 820px){}
@media screen and (max-width: 768px){}
@media screen and (max-width: 425px){
  .newscenter {
    .group_news_container {
      .group_news_box {
        margin-bottom: 50px;
        .sidebar_box {
          width: 30%;
          ul {
            text-align: right;
            padding-right: 10px;
            li::before {
              left: 10px;
            }
          }
        }
        .new_content_box {
          width: 60%;
          .new_content {
            ul {
              li {
                margin-bottom: 50px;
                .content {
                  h2 {
                    font-size: 20px;
                  }
                }
                img {
                  margin-bottom: 20px;
                }
                .right_image {
                  margin-right: 0;
                }
                .left_image {
                  margin-left: 0px;
                }
              }
              li:nth-child(2n) {
                flex-direction: column-reverse;
              }
              li:nth-child(2n-1) {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
</style>